jQuery(document).ready(function($) {

	var $question = $('.faq__question');

	$question.on('click', function(e) {
		
		var $this = $(this);
		var $answer = $this.next('.faq__answer');

		e.preventDefault();

		if(!$answer.is(':visible')) {
			$('.faq__answer').slideUp();
		}

		
		if($this.hasClass('open')) {
			$this.toggleClass('open');
		} else {
			$question.removeClass('open');
			$this.toggleClass('open');
		}	

		
		$answer.slideToggle();
	});

});